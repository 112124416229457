import * as React from "react";
import styled from "@emotion/styled";
import { Link } from 'gatsby';

import { DefaultLayout } from '../components/layouts/default-layout';

// const layoutWidth = 1280;
const layoutWidth = 960;
const layoutPadding = '1em';
const Wrapper = styled.div`
    /* > * + * {
    margin-top: 1em;
    } */
    font-family: 'Noto Sans JP', sans-serif;
    /* font-size: 18px; */

`;
const HeaderImageBlock = styled.div`
    background: #000;
    /* box-shadow: 0 0 .5em rgba(0, 0, 0, .25); */
    padding-top: 3em;

    img {
        display: block;
        margin: 0 auto;
        /* max-width: ${layoutWidth}px; */
        max-width: ${`calc(${layoutWidth}px - 2em)`};
    }
`;
const ContentBlock = styled.div`
    padding: 0 ${layoutPadding};
    max-width: ${layoutWidth}px;
    margin: 0 auto;
`;
const TextBlock = styled.div`
    /* text-align: justify; */

    &:after {
        content: ".";
        visibility: hidden;
        display: block;
        height: 0;
        clear: both;
    }

    > * + * {
        margin-top: 1em;
    }

    h2 {
        /* border-bottom: 1px solid dodgerblue; */
    }

    h3 {
        color: rgba(0, 0, 0, .5);
        font-size: .75em;
        line-height: 1;
        /* padding-bottom: .25em; */
        /* border-bottom: 1px solid rgba(0, 0, 0, .2); */
    }

    p {
        color: rgba(0, 0, 0, .5);
        font-size: .6em;
    }

    a {
        border-bottom: 1px solid;

        &:hover {
            color: #fff;
            background-color: rgba(0, 0, 0, .5);
        }
    }

    pre {
        background-color: #eee;
        font-family: 'Inconsolata', monospace;
        font-size: .5em;
        line-height: 1;
        padding: .5em;
        overflow: auto;
    }

    strong {
        font-weight: bold;
    }
`;
const TweetsList = styled.ul`
    > * + * {
        margin-top: 1em;
    }
`;
const TweetBlock = styled.div`
    padding: .5em 1em;
    border-left: .5em solid dodgerblue;

    a {
        font-size: .6em;
    }
`;

const tweets = [
    {
        url: 'https://twitter.com/nntaleb/status/1074447088459616256',
        body: `I view both globalists and nationalists as the same crap: a reduction in dimensionality, a destruction of the variegated intermediate fractal layers between individual and humanity.
Both aim at monoculture.`,
    },
    {
        url: 'https://twitter.com/andreasklinger/status/1089335043074404353',
        body: `Many say AI automation will create a job crisis.
I agree but my thinking is that it will primarily expose a preexisting education crisis.`,
    },
];

export default function Home() {
    const code = `(() => {

  const alphabet = 'abcdefghijklmnoprstuvwxyz0123456789\n _-:/\\'.split('');

  const encode = (str) => {
    return str.split('').map(l => {
      const letterIndex = alphabet.indexOf(l.toLowerCase());
      if (letterIndex === -1) return l;

      const newIndex = letterIndex === alphabet.length - 1 ? 0 : letterIndex + 1;
      let newLetter = alphabet[newIndex];

      if (l !== alphabet[letterIndex]) newLetter = newLetter.toUpperCase();

      return newLetter;
    }).join('');
  }

  console.log(encode(\`...enter text here ...\`));

})()`;

    return <DefaultLayout>
        <Wrapper>
            <div style={{ backgroundColor: '#fff' }}>
                <HeaderImageBlock>
                    <img src={`/images/editor2.png`} alt="" />
                </HeaderImageBlock>

                <div style={{ padding: '0 0 2em 0', backgroundColor: `hsl(240, 25%, 95%)`}}>
                    <ContentBlock>
                        <div style={{ backgroundColor: '#fff', padding: '2em' }}>
                            <TextBlock>
                                <h2>Lorem, ipsum dolor sit amet</h2>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, illo expedita? Sed consequatur velit hic exercitationem, blanditiis neque sapiente officia perferendis laudantium pariatur minima, mollitia aspernatur aperiam ducimus recusandae ipsam?</p>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, illo expedita? Sed consequatur velit hic exercitationem, blanditiis neque sapiente officia perferendis laudantium pariatur minima, mollitia aspernatur aperiam ducimus recusandae ipsam?</p>
                                <h3>Lorem, ipsum dolor sit amet</h3>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, illo expedita? Sed consequatur velit hic exercitationem, blanditiis neque sapiente officia perferendis laudantium pariatur minima, mollitia aspernatur aperiam ducimus recusandae ipsam?</p>
                                <pre>{code}</pre>
                                <h3>Lorem, ipsum dolor sit amet</h3>
                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, illo expedita? Sed consequatur velit hic exercitationem, blanditiis neque sapiente officia perferendis laudantium pariatur minima, mollitia aspernatur aperiam ducimus recusandae ipsam?</p>
                                <TweetsList>
                                    {
                                        tweets.map(t => {
                                            return <li key={t.url}>
                                                <TweetBlock>
                                                    <div>
                                                        {
                                                            t.body.split('\n').map((p, i) => {
                                                                return <p key={i}>{p}</p>
                                                            })
                                                        }
                                                    </div>
                                                    <div><a href={t.url} target="_blank">{t.url}</a></div>
                                                </TweetBlock>
                                            </li>
                                        })
                                    }
                                </TweetsList>
                            </TextBlock>
                        </div>
                    </ContentBlock>
                </div>
            </div>
        </Wrapper>
    </DefaultLayout>
}
